<template>
  <div
    :class="
      cn(
        'mx-14 flex flex-1 items-center justify-between px-1 md:flex-col md:items-start md:gap-8 lg:mx-0',
        props.class
      )
    "
    dir="rtl"
  >
    <span class="text-2.5xl/10 min-md:whitespace-pre-line w-full pe-5 font-semibold md:text-2xl/10">
      <span>מסלולי ההשקעה המובילים</span>
      <span class="md:block md:leading-none">
        ב
        <LandingHomeChip class="align-middle" color="pink" :text="currentCategory" />
      </span>
    </span>

    <div class="flex w-full justify-end md:justify-center" dir="rtl">
      <SharedRankCard
        v-for="risk in risks"
        :class="cn('pension-shadow-below rank-card -ms-20 max-h-56 max-w-44 first:ms-0 md:-ms-24')"
        :rank="parseInt(risk)"
        :background-color="risk"
        :rank-background="risk"
        :classes="{
          body: 'md:p-xxs',
        }"
      >
        <UiInfo
          class="mb-2.5 border-b pb-2.5"
          has-image
          :classes="{
            image: 'size-5 border bg-white',
            wrapper: 'gap-2.5',
          }"
          :image="getImageUrl(`homepage/pension/circle-rank-${risk}.png`)"
        >
          <template #title>
            <UiTransition
              name="scrollY"
              duration="duration-500"
              :delay="{ enter: 'delay-150' }"
              mode="out-in"
            >
              <span class="text-xs/5 font-medium" :key="currentCategory">
                {{ currentCategory }}
              </span>
            </UiTransition>
          </template>
        </UiInfo>

        <div class="flex justify-between text-xs/none text-black/50">
          <UiTransition name="scrollY" duration="duration-500" :delay="{ enter: 'delay-200' }" mode="out-in">
            <span class="text-black/50" :key="currentCategory">
              {{ 550 + parseInt(risk) }}
            </span>
          </UiTransition>

          <UiTransition name="scrollY" duration="duration-500" :delay="{ enter: 'delay-300' }" mode="out-in">
            <div class="flex gap-1 text-black/50" :key="currentCategory">
              <span>שנה</span>
              <UiIconCircleSeparator />
              <UiPositiveNegative class="inline-flex" :value="14.7" :format="toPercent" />
            </div>
          </UiTransition>
        </div>
      </SharedRankCard>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { UiCardClasses } from "@finq/ui/components/ui/Card.vue"

const props = defineProps<{
  class?: ClassValue
  classes?: { root?: ClassValue; card?: Partial<UiCardClasses> }
}>()

const { isMobile } = useDisplay()
const risks = computed(() =>
  isMobile.value ? (["3", "2", "1"] as const) : (["5", "4", "3", "2", "1"] as const)
)

const { t } = useI18n({ useScope: "local" })

const categories = [
  "קרן פנסיה מקיפה",
  "קרן פנסיה משלימה",
  "קופת תגמולים",
  "גמל להשקעה",
  "חיסכון לכל ילד",
  "ביטוח מנהלים",
  "פוליסת חיסכון פרט",
  "קופה מרכזית לפיצויים",
]

const currentCategoryIndex = ref(0)
const currentCategory = computed(() => categories[currentCategoryIndex.value])

const { counter } = useInterval(6000, { controls: true, immediate: true })

watch(
  () => counter.value,
  () => {
    currentCategoryIndex.value = (currentCategoryIndex.value + 1) % categories.length
  }
)
</script>

<i18n lang="json">
{
  "he": {
    "headline": "מסלולי ההשקעה המובילים ב {category}"
  },
  "en": {}
}
</i18n>

<style lang="scss" scoped>
.rank-card {
  width: 100%;
  min-width: 10.5rem;
}

.pension-shadow-below:not(:first-child) {
  clip-path: inset(0px -80px);
  box-shadow:
    14px 0px 14px rgba(115, 165, 144, 0.07),
    8px 0px 8px rgba(115, 165, 144, 0.07),
    4px 0px 4px rgba(115, 165, 144, 0.05),
    2px 0px 2px rgba(115, 165, 144, 0.02),
    1px 0px 1px rgba(115, 165, 144, 0.02);
}
</style>
